import Vue from 'vue';
import VueRouter from 'vue-router';
import Collect from '../views/Collect';
import DingTalk from '../views/DingTalk';
import CollectForm from '../views/CollectForm';
import Requestform from '../views/collectitems/requestform';
import Itemlist from '../views/collectitems/itemlist';

Vue.use(VueRouter);

// 定义路由
const routes=[
    { path: '/', name: 'DingTalk', component: DingTalk },
    {
        path: '/collect', name: 'Collect', component: Collect,
        children:[
            {
                path:'requestform',
                component:Requestform
            },
            {
                path:'itemlist',
                component:Itemlist
            }
        ]
    },
    {path:'/CollectForm',name:'CollectForm',component:CollectForm}
];
// 创建 router 实例，然后传 `routes` 配置
const router=new VueRouter({
    routes // (缩写) 相当于 routes: routes
});

export default router;
import Vue from 'vue';
import { Button,Container,
    Header,
    Aside,
    Main,
    Footer,Menu,
    Submenu,
    MenuItem, 
    Row,
    Col,
    Form,
    FormItem,
    Input,
    InputNumber,
    Select,
    Option,} from 'element-ui';

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(Button);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Row);
Vue.use(Col);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Select);
Vue.use(Option);
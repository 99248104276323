<template>
  <div class="collectform">
      <el-form ref="collectform" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="物品仓库" prop="store">
            <el-select v-model="form.store" placeholder="请选择物品仓库" style="width:100%;">
                <el-option label="济南仓库" value="1"></el-option>
                <el-option label="酒水仓库" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="物品品类" prop="category">
            <el-select v-model="form.category" placeholder="请选择物品品类" style="width:100%;">
                <el-option label="文件类" value="1"></el-option>
                <el-option label="财务类" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="物品名称" prop="name">
            <el-select v-model="form.name" placeholder="请选择物品名称" style="width:100%;">
                <el-option label="黑色中性笔" value="1"></el-option>
                <el-option label="红色中性笔" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="领取数量" prop="number">
            <el-input v-model.number="form.number" placeholder="请输入领取数量"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('collectform')">提交</el-button>
            <el-button @click="resetForm('collectform')">重置</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi';
export default {
    name:'CollectForm',
    data(){
        var checkNumber=(rule, value, callback) => {
            if (value < 1) {
              callback(new Error('领取数量必须大于0'));
            } else {
              callback();
            }
        };
        return {
            form:{
                name:'',
                category:'',
                number:0
            },
            rules: {
                store:[
                    { required: true, message: '请选择物品仓库', trigger: 'blur' },
                ],
                category: [
                    { required: true, message: '请选择物品品类', trigger: 'blur' },
                ],
                name: [
                    { required: true, message: '请选择物品名称', trigger: 'blur' },
                ],
                number: [
                    { required: true, message: '请选择领取数量', trigger: 'blur' },
                    { type: 'number', message: '领取数量必须为数字值'},
                    { validator: checkNumber, trigger: 'blur' }
                ]
            }
        }
    },
    mounted(){
      if(this.$route.query.type=='dingding'){
        console.log('dingding')
        dd.ready(function(){
          dd.runtime.permission.requestAuthCode({
            corpId:"",
            onSuccess:function(result){
              console.log(result)
            },
            onFail:function(err){
              console.log(err)
            }
          });
        });
      }else{
        console.log('null')
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
              console.log(JSON.stringify(this.form));
              console.log(JSON.parse(JSON.stringify(this.form)));

              this.postRequest('Ding/createWorkrecord',this.form).then(resp=>{
                // if(resp){
                  console.log(resp)
                // }
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
}
</script>

<style>
.collectform{
    margin: 20px;
}
</style>
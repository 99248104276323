<template>
  <div>
        <el-row :gutter="10">
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                <router-link :to="{path:'/CollectForm?type=dingding'}">物品领用</router-link>
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                <router-link :to="{ path: '/collect' }">物品管理</router-link>
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
                333333
            </el-col>
        </el-row>
  </div>
</template>

<script>
export default {
    name:'DingTalk'
}
</script>

<style>

</style>
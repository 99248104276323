<template>
  <div>
        <el-container>
            <el-header>物品领用</el-header>
            <el-container>
                <el-aside width="200px">
                    <el-menu router unique-opened>
                        <el-menu-item :index="item.path" v-for="(item,index) in collectMenu" :key="index">{{item.value}}</el-menu-item>
                    </el-menu>
                </el-aside>
                <el-container>
                <el-main>
                    <template v-if="this.$router.currentRoute.path!='/collect'">
                        processCode=PROC-1A5DE7D2-90E8-47D3-B9A1-902AAA1AA5A4
                    </template>
                    <div v-else>axx</div>
                    <router-view />
                </el-main>
                <el-footer>联越小王子</el-footer>
                </el-container>
            </el-container>
        </el-container>
  </div>
</template>

<script>
export default {
    name:'Collect',
    data(){
        return {
            collectMenu:[
                {
                    path:'/collect/requestform',
                    value:'物品领用申请'
                },
                {
                    path:'/collect/itemlist',
                    value:'物品名录'
                },
                {
                    path:'/collect/categorylist',
                    value:'物品类目表'
                },
                {
                    path:'/collect/entryform',
                    value:'物品入库单'
                },
                {
                    path:'/collect/storelist',
                    value:'物品仓库表'
                },
            ]
        }
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    }
}
</script>

<style>

</style>
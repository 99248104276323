import axios from 'axios';
import { Message } from 'element-ui'
import router from '../router'

axios.defaults.baseURL='http://api.tp5.io/';

// 请求拦截器
axios.interceptors.request.use(config => {
    // 如果存在token，则请求自动携带token
    if (sessionStorage.getItem('tokenStr')) {
        config.headers['Authorization'] = sessionStorage.getItem('tokenStr');
    }
    return config;
}, error => {
    console.log(error)
})


// 响应拦截器
axios.interceptors.response.use(success => {
    console.log(success)
    // 业务逻辑错误
    if (success.status && success.status == 200) {
        if (success.data.code == 0) {
            if (success.data.message) {
                Message.success({ message: success.data.message });
            }
            return success.data;
        }
    }
    // if (success.data.code == 500 || success.data.code == 401 || success.data.code == 403) {
    if (success.data.message) {
        Message.error({ message: success.data.message });
    }
    return;
    // }
}, error => {
    if (error.response.code == 504 || error.response.code == 404) {
        Message.error({ message: '找不到服务器！' });
    } else if (error.response.code == 403) {
        Message.error({ message: '权限不足！，请联系管理员' });
    } else if (error.response.code == 401) {
        Message.error({ message: '尚未登录，请登录' });
        router.replace('/')
    } else {
        if (error.response.data.message) {
            Message.error({ message: error.response.data.message });
        } else {
            Message.error({ message: '未知错误！' });
        }
    }
    return;
});

let base = '';
// 传送 json 格式的 post 请求
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
};
// 传送 json 格式的 put 请求
export const putRequest = (url, params) => {
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
};
// 传送 json 格式的 get 请求
export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        data: params
    })
};
// 传送 json 格式的 delete 请求
export const deleteRequest = (url, params) => {
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params
    })
};
import Vue from 'vue'
import App from './App.vue'
import router from './router';
import { getRequest, postRequest, putRequest, deleteRequest } from './utils/api';
import './elementUI';

Vue.config.productionTip = false

// 插件形式使用请求
Vue.prototype.getRequest = getRequest;
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.deleteRequest = deleteRequest;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
